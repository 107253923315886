// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import website from '../seo/website.json'
import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Buy Or Contribute',
  slug: '/buy-or-contribute',
  abstract:
    "The author and the publisher of this book have chosen to offer this book, in it's entirety, to read online as a web-book. This is not for free, but available on a Gift-Economy and Pay-What-Feels-Right basis. We want you to enjoy the book, learn from the book, but also support the book and this format of open publishing. If it was worth your time, please consider contributing or buying the print version of this book.",
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Buy Or Contribute', slug: '/buy-or-contribute' },
  ],
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ ...props }) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>Support open publishing</h1>
      <p>
        The author and the publisher of this book have chosen to offer this
        book, in it's entirety, to read online as a open-access book. This is
        not for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. We want you to enjoy the book, learn from the book, but also
        support the book and this format of open publishing. If it was worth
        your time, please consider contributing or buying the print version of
        this book.
      </p>
      <h2>Buy the print version of the book</h2>
      <p>
        <Link to={website.shopUrl}>
          Please follow{' '}
          <span style={{ textDecoration: 'underline' }}>this link</span> to buy
          the print version of this book.
        </Link>
      </p>
      <h2>Contribute directly</h2>
      <p>
        <Link
          to={`mailto:prisma@auroville.org.in?subject=${encodeURIComponent(
            `re: contribution for the web-book ${about.altTitle}`
          )}&body=${encodeURIComponent(
            `Hello! I would like to contribute ₹1000 for the open-access book ${about.altTitle}. Please send me further details. Thank you.`
          )}`}
        >
          Please <span style={{ textDecoration: 'underline' }}>click here</span>{' '}
          to write to PRISMA Books a letter indicating your intent to contribute
          and they will share further instructions.
        </Link>
      </p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
